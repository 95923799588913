@import url("https://fonts.googleapis.com/css?family=Muli:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Permanent+Marker");
@import url("https://fonts.googleapis.com/css?family=Gaegu");

html {
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
  font-family: "Muli", sans-serif;
}

body {
  margin: 0;
  padding: 0;
}
